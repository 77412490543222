import React from "react";
import FooterTwo from "../components/footers/FooterTwo";
import ClientLogo from "../features/common/ClientLogo";
import Counter from "../features/landingpage-two/Counter";
import Features from "../features/landingpage-two/Features";
import FunfactVideo from "../features/landingpage-two/FunfactVideo";
import FunfactVideoHoplias from "../features/landingpage-two/FunfactVideoHoplias";
import { Link } from "react-router-dom";
import Digital from "../assets/images/landing/banner.png";
import DigitalEn from "../assets/images/landing/en-banner.png";
import DigitalEs from "../assets/images/landing/sn-banner.png";
import LogoCan from '../assets/images/products/logo-cancela.png'
import '../../node_modules/react-modal-video/scss/modal-video.scss'
import { useTranslation } from 'react-i18next';


const LandingPageTwo = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      {/* <section className="md:pt-[160px] md:pb-[170px] pt-[100px] pb-[120px] relative bg-cover bg-center bg-themedark" style={{ paddingBottom: '80px' }}> */}
      <section className="pt-[124px] pb-20 bg-[url('../images/header-points.png')] bg-cover bg-center relative ">
        {/* <div className="absolute inset-0 bg-cover bg-center bg-no-repeat w-full h-full bg-[url('../images/home_sec_layer-2.png')]"></div> */}
        <div className="bg-[url('../images/hero-4-bg.png')] absolute inset-0 bg-no-repeat bg-center bg-cover"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 lg:grid-cols-5 items-center gap-[30px]">
            <div className="lg:col-span-3">
              <p className="py-2.5 px-5 bg-white/[0.03] italic inline-block rounded-lg text-custom mb-4 md:mb-0" style={{ color: 'white', background: '#E6721F' }}>
                {t('innovation')}
              </p>
              <h1 className="mt-4 md:text-[56px] text-[34px] leading-[1.2] font-bold text-xl" style={{ color: '#00365a' }}>
                {t('idea_off_paper')}
              </h1>
              {/* <p className="mt-6 text-lg text-white/40" style={{ color: '#6c757d' }}>{dataBlock.subTitle}</p> */}
              {/* <div className="mt-12">
                <Link to="#" className="btn-custom ">
                  Entrar em contato!
                </Link>
              </div> */}
              <div className="bg-white/[8%] w-full h-px block my-10 md:my-[50px]"></div>
              {/* <div className="sm:space-y-0 space-y-4">
                <div className="items-center sm:inline-block block">
                  <span className="text-muted capitalize">Produto:</span>
                </div>
                <div className="inline-block">
                  <img
                    src={Epublicity}
                    alt="client7"
                    className="h-[26px] my-2 mx-3 opacity-70 inline-block"
                  />
                </div>
              </div> */}
            </div>
            <div className="lg:col-span-2" style={{ width: '100%', paddingLeft: '18%' }}>
              <img src={i18n.language === 'pt' ? Digital : i18n.language === 'en' ? DigitalEn : DigitalEs } alt="Logo daftar" style={{ borderRadius: '0.5rem', borderCollapse: 'collapse', boxShadow: '#e6721f36' }}/>
            </div>
          </div>
        </div>
      </section>
      <Features />
      <ClientLogo/> 
      <FunfactVideo/>
      {/* <RightPlan/>  */}
      <Counter/>
      <FunfactVideoHoplias />
      <section className="bg-white pt-[90px] pb-5 md:pt-[120px] md:pb-20">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="bg-light text-center py-10 md:py-[60px] px-[30px]">
              <div className="max-w-[430px] mx-auto">
                {/* <form className="relative">
                  <input
                    type="text"
                    className="form-input h-[52px] py-3.5 px-4 text-sm !pr-[160px] text-dark rounded-full bg-white border border-solid !border-[#ededed]"
                    placeholder="Email Address"
                  />
                  <button className="absolute tracking-[1px] font-normal text-sm rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom">
                    Get Started
                  </button>
                </form> */}
                <div className="md:text-center" style={{ width: '56%',  paddingLeft: '40%'}}>
                  <img className="md:text-center" alt="Logo cancela multas" src={LogoCan} style={{ borderRadius: '0.5rem', borderCollapse: 'collapse' }}/>
                </div>
              </div>
              <div className="h-[3px] bg-custom w-14 inline-block mt-10 mb-5 mx-0"></div>
              <div className="max-w-[650px] mx-auto">
                <h1 className="font-semibold leading-[1.4] text-[32px] md:text-[46px]">
                  {t('fine_docs')}
                </h1>
                <p className="text-muted mx-auto md:text-lg mt-4 max-w-[500px]">
                  {t('cancela_multas')}
                </p>
                <div className="mt-6">
                  {/* <button
                    onClick={() => setIsOpen(true)}
                    className="lightbox text-white"
                  >
                    <i className="mdi mdi-play bg-custom h-[66px] w-[66px] leading-[66px] text-[34px] shadow-[0px_0px_0px_6px_rgb(10,161,149,0.15)] rounded-full inline-block text-center"></i>
                  </button> */}
                  {/* <ModalVideo
                    channel="youtube"
                    isOpen={isOpen}
                    videoId="tmtxeYZwwxY"
                    onClose={() => setIsOpen(false)}
                  /> */}
                  <div className="md:text-center">
                    <Link to="https://www.cancelamultas.com.br/inicio/" className="btn-custom" target="_blank">
                      {t('learn_more')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Coustomer/> */}
      <FooterTwo/>
    </>
  );
};

export default LandingPageTwo;
