import React from "react";
import feature3 from '../../assets/images/features/3.png';
import lapTop from '../../assets/images/about_lap.jpg';
import penruller from '../../assets/images/icon/pen&ruller.svg';
import code from '../../assets/images/icon/code.svg';
import headphones from '../../assets/images/icon/headphones.svg';
import groupchat from '../../assets/images/icon/groupchat.svg';
import img1 from '../../assets/images/icon/sketch.svg'
import img2 from '../../assets/images/icon/bulb.svg'
import img3 from '../../assets/images/icon/lnterselect.svg'
import { useTranslation } from 'react-i18next';

const Features = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="bg-[url('../images/home_sec_features.jpg')] bg-center py-20 bg-white relative">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[30px]">
            <div key="1" className="bg-white py-[26px] px-5 rounded-md relative -top-[130px] border border-[#e7e7e7]/50">
              <div className="flex items-center">
                <div className="bg-custom shrink-0 h-[50px] w-[50px] leading-[50px] rounded-lg text-center mr-4">
                  <img
                    src={img1}
                    className="h-[26px] mx-auto inline-block"
                    alt="..."
                  />
                </div>
                <div className="grow">
                  <h5 className="font-bold text-xl">
                    {t('quality_assurance')}
                  </h5>
                </div>
              </div>
            </div>
            <div key="2" className="bg-white py-[26px] px-5 rounded-md relative -top-[130px] border border-[#e7e7e7]/50">
              <div className="flex items-center">
                <div className="bg-custom shrink-0 h-[50px] w-[50px] leading-[50px] rounded-lg text-center mr-4">
                  <img
                    src={img2}
                    className="h-[26px] mx-auto inline-block"
                    alt="..."
                  />
                </div>
                <div className="grow">
                  <h5 className="font-bold text-xl">
                    {t('custom_dev')}
                  </h5>
                </div>
              </div>
            </div>
            <div key="3" className="bg-white py-[26px] px-5 rounded-md relative -top-[130px] border border-[#e7e7e7]/50">
              <div className="flex items-center">
                <div className="bg-custom shrink-0 h-[50px] w-[50px] leading-[50px] rounded-lg text-center mr-4">
                  <img
                    src={img3}
                    className="h-[26px] mx-auto inline-block"
                    alt="..."
                  />
                </div>
                <div className="grow">
                  <h5 className="font-bold text-xl">
                    {t('fully_remote')}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {t('challenging_solutions')}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {t('purpose_driven')}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-16 items-center">
            <div>
              <img
                src={feature3}
                alt="business"
                className="mx-auto"
              />
            </div>
            <div>
              <h3 className="md:text-[32px] text-2xl leading-[1.4] font-bold">
                {t('innovation_beyond')}
              </h3>
              <div className="space-y-8 mb-4">
                <div className="flex items-start gap-5 mt-8">
                  <div className="grow">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-1 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold mb-4">
                      {t('people_without_borders')}
                    </h3>
                    <p className="text-[15px] leading-[22px] text-muted">
                      {t('no_borders')}
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-5 mt-8">
                  <div className="grow">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-1 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold mb-4">
                      {t('present_future')}
                    </h3>
                    <p className="text-[15px] leading-[22px] text-muted">
                      {t('future_trends')}
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-5 mt-8">
                  <div className="grow">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-1 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold mb-4">
                      {t('without_borders')}
                    </h3>
                    <p className="text-[15px] leading-[22px] text-muted">
                      {t('remote_model')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-16 items-center">
            <div>
              <h3 className="text-[32px] font-bold leading-[1.4] max-w-[520px]">
                {t('our_services')}
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] mt-6">
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={penruller}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">{t('rd')}</h5>
                    <p className="text-muted mt-4">
                      {t('ai_solutions')}
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={code}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">{t('custom_software')}</h5>
                    <p className="text-muted mt-4">
                      {t('custom_apps')}
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={headphones}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">{t('tech_consulting')}</h5>
                    <p className="text-muted mt-4">
                      {t('guide_future')}
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={groupchat}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">{t('sector_solutions')}</h5>
                    <p className="text-muted mt-4">
                      {t('various_sectors')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div img-fluid mx-auto d-block>
              <img
                src={lapTop}
                alt="business"
                style={{ borderRadius: '30px', boxShadow: '0px 0px 56px rgba(69, 69, 69, .2)' }}
                className="img-fluid mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
