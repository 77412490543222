import React from "react";
import { Link } from "react-router-dom";
import '../../../node_modules/react-modal-video/scss/modal-video.scss'
import LinkIcon from "../../assets/images/landing/link-fill.svg";
import Epublicity from "../../assets/images/landing/epublicity.png";
import { useTranslation } from 'react-i18next';

const FunfactVideo = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="md:mt-24 mt-10 relative pb-20 bg-themedark">
        <div className="bg-[url('../images/home_sec_video.png')] absolute inset-0 w-full"></div>
        <div className="container relative">
          <div className="grid grid-cols-1">
            <div className="max-w-[800px] mx-auto relative -top-[70px]">
              <Link target="_blank" to="https://epublicity.com.br/">
                <button className="lightbox mdi w-[65px] h-[65px] leading-[65px] text-center text-[35px] absolute top-1/2 bg-custom left-1/2 z-10 rounded-full text-white -translate-y-1/2 -translate-x-1/2">
                  {/* <i className="mdi mdi-play w-[65px] h-[65px] leading-[65px] text-center text-[35px] absolute top-1/2 bg-custom left-1/2 z-10 rounded-full text-white -translate-y-1/2 -translate-x-1/2"></i> */}
                  <img src={LinkIcon} alt="screen1" style={{ paddingLeft: '35%', textAlign: 'center' }} />
                </button>
              </Link>
              <img src="https://epublicity.com.br/assets/img/landing-page/header.png" alt="screen1" className="" style={{ borderRadius: '0.5rem', borderCollapse: 'collapse', width: '100%' }} />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div className="text-white max-w-[500px]">
              <h2 className="text-[35px] font-bold leading-[1.4]">
                <img
                    src={Epublicity}
                    alt="client7"
                    className="h-[50px] my-2 mx-3 opacity-70 inline-block"
                  />
              </h2>
              <p className="text-lg font-bold mb-4" style={{ color: 'white' }}>
                {t('public_management')}
              </p>
            </div>
            <div className="md:text-left">
              <Link to="https://epublicity.com.br/" className="btn-custom" target="_blank">
                {t('learn_more')}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FunfactVideo;
