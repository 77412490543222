import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from '../components/headers/Header'
import HeaderNotification from '../components/headers/HeaderNotification';

const LandingPageLayout = () => {

  return (
    <>
      {/* <HeaderNotification/> */}
      <Header/>
      <Outlet/>
    </>
  )
}

export default LandingPageLayout
