import React from "react";
import CountUp from "react-countup";
import { useTranslation } from 'react-i18next';

const Counter = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="py-20 bg-white relative">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="rounded-2xl py-[60px] px-[30px] bg-custom relative before:bg-no-repeat before:bg-cover before:absolute before:bg-[url('../images/counter_bg.png')] before:left-0 before:bottom-0 before:w-full before:h-[120px]">
              <div className="relative text-white text-center">
                <h2 className="font-bold text-[32px] md:text-[40px] z-10">
                  {t('proj_experience')}
                </h2>
                {/* <p className="mx-auto mt-6 max-w-[600px] text-lg">
                  Due to its widespread use as filler text for layouts,
                  non-readability is of great importance.
                </p> */}
              </div>
              <div className="text-center mt-12 relative">
                <h1 className="font-bold text-white tracking-[7px] text-[46px] md:text-[80px]">
                  +<CountUp start={0} end={8} /> {t('years_8')}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Counter;
