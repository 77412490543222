import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../../src/assets/images/logos/logo_daftar.png";
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div className="my-5">
      <select 
        onChange={changeLanguage} 
        className="btn btn-sm btn-custom navbar-btn btn-rounded"
        defaultValue={i18n.language}
        style={{ paddingRight: '3rem' }}
      >
        <option value="en" className="text-muted mx-auto md:text-lg mt-5">{t('english')}</option>
        <option value="pt" className="text-muted mx-auto md:text-lg mt-5">{t('portuguese')}</option>
        <option value="es" className="text-muted mx-auto md:text-lg mt-5">{t('spanish')}</option>
      </select>
    </div>
  );
};

const Header = () => {

  const menuActive = () => {
    const menu = document.querySelector(".mobile-menu");
    menu.classList.toggle("hidden");
  };

  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light fixed w-full horizontal-nav" style={{ alignContent: 'center' }}>
          <div className="container">
            <div className="flex items-center justify-between">
              <NavLink className="navbar-brand" to="/">
                <img src={logo} className="mr-2 w-[200px]" alt="logo" />
              </NavLink>
              <button
                onClick={() => menuActive()}
                className="navbar-toggler hidden text-end mobile-menu-button"
              >
                <span className="navbar-toggler-icon">
                  <i className="ti-menu icon-align-right"></i>
                </span>
              </button>

              <div
                className="navbar-collapse flex-1 mobile-menu hidden min-[991px]:block"
                id="navbarNav"
              >
                <ul className="navbar-nav justify-end horizontal-menu">
                  {/* <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/landing")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Landing</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/landing/">Overview</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page1">Landing Page 1</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page2">Landing Page 2</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page3">Landing Page 3</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page4">Landing Page 4</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/pages")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Pages</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/pages/">About</NavLink>
                      </li>
                      <li>
                        <NavLink to="/pages/servicies">Service</NavLink>
                      </li>
                      <li className="dropdown group/submenu">
                        <NavLink
                          className={({ isActive }) => {
                            return location.pathname.match("/pages/widget")
                              ? "active dropdown-toggle"
                              : "dropdown-toggle";
                          }}
                        >
                          Widget
                        </NavLink>
                        <ul className="dropdown-menu hidden group-hover/submenu:block">
                          <li>
                            <NavLink to="/pages/widget/">Pricing</NavLink>
                          </li>
                          <li>
                            <NavLink to="/pages/widget/testimonials">
                              Testimonial
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/pages/widget/faq">FAQs</NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <NavLink to="/pages/contact">Contact</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="scroll dropdown group/menu">
                    <NavLink
                     
                      className={({ isActive }) => {
                        return location.pathname.match("/account")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Account</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/account/">Login</NavLink>
                      </li>
                      <li>
                        <NavLink to="/account/register">Ragister</NavLink>
                      </li>
                      <li>
                        <NavLink to="/account/pwreset">Password Reset</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="scroll dropdown group/menu">
                    <NavLink
                     
                      className={({ isActive }) => {
                        return location.pathname.match("/documentation")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Docs</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/documentation/">Documentation</NavLink>
                      </li>
                      <li>
                        <NavLink to="/documentation/changelog">
                          Changelog
                        </NavLink>
                      </li>
                    </ul>
                  </li> */}
                  <div>
                    <ul className="flex items-center space-x-3 md:justify-end justify-center" style={{ marginTop: '12%' }}>
                      <li>
                        <Link
                          to="https://www.instagram.com/daftar.digital?igsh=MWJ0cmk0N2w4d2pt"
                          target="_blank"
                          className="t w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                        >
                          <i className="mdi mdi-facebook"></i>
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="#"
                          className="w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                        >
                          <i className="mdi mdi-twitter"></i>
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="https://www.instagram.com/daftar.digital?igsh=MWJ0cmk0N2w4d2pt"
                          target="_blank"
                          className=" w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                        >
                          <i className="mdi mdi-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <button className="btn btn-sm btn-custom navbar-btn btn-rounded">
                    Explorar Agora!
                  </button> */}
                  <div style={{ marginLeft: '1rem' }}>
                    <LanguageSelector />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
