import React from "react";
import logo from "../../../src/assets/images/logo_daftar-fundo-tran.png";
import logoIcon from "../../../src/assets/images/d.d_daftar-sem-fundo.png";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const FooterTwo = () => {
  const { t } = useTranslation();

  return (
    <>
      <footer className="bg-themedark pt-20 pb-10">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-[30px] items-start">
            <div className="order-2 lg:order-1 mt-4 md:mt-0 md:col-span-2 lg:col-auto">
              <img
                src={logo}
                alt="logo light"
                className="img-fluid w-[200px] block text-md-center text-sm-center"
              />
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              {/* <h6 className="text-white text-lg">Cliente</h6> */}
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    to="https://epublicity.com.br/"
                    target="_blank"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Epublicity
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://epublicity.com.br/"
                    target="_blank"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    {t('epublicity_access')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              {/* <h6 className="text-white text-lg">Help Center</h6> */}
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    to="https://hoplias.com/login"
                    className="hover:text-white inline-block transition-all duration-200"
                    target="_blank"
                  >
                    Hoplias
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://hoplias.com/login"
                    className="hover:text-white inline-block transition-all duration-200"
                    target="_blank"
                  >
                    {t('hoplias_access')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              {/* <h6 className="text-white text-lg">Help Center</h6> */}
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    to="https://www.cancelamultas.com.br/inicio/"
                    className="hover:text-white inline-block transition-all duration-200"
                    target="_blank"
                  >
                    Cancela Multas
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.cancelamultas.com.br/inicio/"
                    className="hover:text-white inline-block transition-all duration-200"
                    target="_blank"
                  >
                    {t('cancela_access')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              {/* <h6 className="text-white text-lg">Help Center</h6> */}
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    to="#"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    {t('client_area')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    {t('help_center')}
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="lg:col-span-3 md:col-span-6 order-1 lg:order-2">
              <div className="bg-custom rounded max-w-[460px] mx-auto relative py-10 px-5 -mt-[140px]">
                <div className="text-center">
                  <h2 className="font-bold text-[32px] text-white md:text-[40px] capitalize mb-2 relative z-10">
                    Contato e Atualizações
                  </h2>
                  <p className="text-white mx-auto text-lg max-w-[600px] mt-6">
                  Deixe seu email e entraremos em contato. Ao informar seu email você está aceitando receber nossas atualizações por email.
                  </p>
                </div>
                <form className="mt-12 space-y-4">
                  <input
                    type="email"
                    className="form-input border border-solid h-[50px] rounded inline-block !border-white/[31%] bg-white/[15%] text-white placeholder:text-white/60"
                    placeholder="Seu email.."
                    required=""
                  />
                  <button type="submit" className="btn-white rounded-[5px] w-full">
                    Enviar Agora
                  </button>
                </form>
              </div>
            </div> */}
            <div className="order-2 lg:order-1 mt-4 md:mt-0 md:col-span-2 lg:col-auto">
              <img
                src={logoIcon}
                alt="logo light"
                className="img-fluid w-[200px] block text-md-center text-sm-center"
              />
            </div>
          </div>
          <div className="bg-white/[7%] h-px w-full mt-12 mb-8"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div>
              <p className="mb-0 text-white/50 text-center md:text-left">
                {t('footer')}
              </p>
            </div>
            <div>
              <ul className="flex items-center space-x-3 md:justify-end justify-center">
                <li>
                  <Link
                    target="_blank"
                    to="https://www.instagram.com/daftar.digital?igsh=MWJ0cmk0N2w4d2pt"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-facebook"></i>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="#"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-twitter"></i>
                  </Link>
                </li> */}
                <li>
                  <Link
                    target="_blank"
                    to="https://www.instagram.com/daftar.digital?igsh=MWJ0cmk0N2w4d2pt"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterTwo;
