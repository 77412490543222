import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "innovation": "Innovation, Transformation, Digital",
      "idea_off_paper": "We take your idea off the paper!",
      "need_pro_devs": "Does your project need professional devs?",
      "web_app_specialists": "We are specialists in web and app development",
      "quality_assurance": "Quality Assurance",
      "custom_dev": "Custom Development",
      "fully_remote": "Fully Remote",
      "challenging_solutions": "We offer solutions for the most challenging demands",
      "purpose_driven": "We are driven by a greater purpose: using technology as a powerful tool to drive the future. We are passionate about creating digital solutions. We are a fully remote team, connected in different places.",
      "innovation_beyond": "Innovation Beyond Limits",
      "people_without_borders": "People & Without Borders",
      "no_borders": "We believe that innovation has no borders. Our team is driven by curiosity and the relentless pursuit of challenges.",
      "present_future": "Present & Future",
      "future_trends": "We experiment with new technologies, explore future trends, and create revolutionary solutions that take your projects to unimaginable levels.",
      "without_borders": "Without & Borders",
      "remote_model": "And best of all? even thousands of kilometers away, thanks to our 100% remote work model.",
      "our_services": "Our Services",
      "rd": "Research and Development",
      "ai_solutions": "We use AI to create intelligent and automated solutions that optimize processes, improve decision-making, and drive your business growth.",
      "custom_software": "Customized Software",
      "custom_apps": "We transform your ideas into reality with custom-made applications and software, created to meet your unique needs.",
      "tech_consulting": "Technological Innovation Consulting",
      "guide_future": "We guide your company towards the future, identifying growth opportunities and applying disruptive technologies to boost your results.",
      "sector_solutions": "Solutions for Various Sectors",
      "various_sectors": "Business, agriculture, third sector, or public management, we develop intelligent and effective solutions to make processes more efficient and scalable.",
      "initiatives": "Our Initiatives",
      "public_management": "For public managers and the third sector. Our platform aims to simplify public management, allowing you to monitor all activities provided to beneficiaries through metrics and analytical reports.",
      "learn_more": "Learn more",
      "proj_experience": "Experience in project development",
      "years_8": "years",
      "karyotyping": "An experimental web application designed for the automatic and supervised assembly of karyotypes. This platform uses advanced technologies to simplify the karyotyping process, making it accessible and efficient for researchers.",
      "fine_docs": "Automation in creating documents for traffic fines.",
      "cancela_multas": "Cancela Multas makes it accessible to everyone to create the necessary appeal documents to be filed with the competent authorities.",
      "epublicity_access": "Access ePublicity",
      "hoplias_access": "Access Hoplias",
      "cancela_access": "Access Cancela Multas",
      "client_area": "Client area",
      "help_center": "Help center",
      "footer": "© daftar.digital all rights reserved. MG - Belo Horizonte CNPJ: 47.732.010/0001-85",
      "portuguese": "Portuguese",
      "english": "English",
      "spanish": "Spanish"
    }
  },
  pt: {
    translation: {
      "innovation": "Inovação, Transformação, Digital",
      "idea_off_paper": "Tiramos a sua idéia do papel!",
      "need_pro_devs": "Seu projeto precisa de devs profissionais?",
      "web_app_specialists": "Nós somos especialistas em desenvolvimento web e aplicativos",
      "quality_assurance": "Quality assurance",
      "custom_dev": "Desenvolvimento Customizado",
      "fully_remote": "Totalmente Remoto",
      "challenging_solutions": "Oferecemos Soluções Para As Demandas Mais Desafiadoras",
      "purpose_driven": "Somos movidos por um propósito maior: usar a tecnologia como uma poderosa ferramenta para impulsionar o futuro. Somos apaixonados por criar soluções digitais. Somos uma equipe totalmente remota, conectada em diferentes lugares.",
      "innovation_beyond": "Inovação Além do Limite",
      "people_without_borders": "Pessoas & Sem Fronteiras",
      "no_borders": "Acreditamos que a inovação não tem fronteiras. Nossa equipe é movida pela curiosidade e pela busca incessante por desafios.",
      "present_future": "Presente & Futuro",
      "future_trends": "Experimentamos novas tecnologias, exploramos tendências futuras e criamos soluções revolucionárias que levam seus projetos a patamares inimagináveis.",
      "without_borders": "Sem & Fronteiras",
      "remote_model": "E o melhor de tudo? mesmo a milhares de quilômetros de distância, graças ao nosso modelo de trabalho 100% remoto.",
      "our_services": "Nossos Serviços",
      "rd": "Pesquisa e Desenvolvimento",
      "ai_solutions": "Utilizamos a IA para criar soluções inteligentes e automatizadas que otimizam processos, melhoram a tomada de decisões e impulsionam o crescimento do seu negócio.",
      "custom_software": "Software Personalizado",
      "custom_apps": "Transformamos suas ideias em realidade com aplicativos e softwares feitos sob medida, criados para atender às suas necessidades únicas.",
      "tech_consulting": "Consultoria em Inovação Tecnológica",
      "guide_future": "Guiamos sua empresa rumo ao futuro, identificando oportunidades de crescimento e aplicando tecnologias disruptivas para impulsionar seus resultados.",
      "sector_solutions": "Soluções para Diversos Setores",
      "various_sectors": "Empresarial, agricultura, terceiro setor ou na gestão pública, desenvolvemos soluções inteligentes e eficazes para tornar os processos mais eficientes e escaláveis.",
      "initiatives": "Nossas Iniciativas",
      "public_management": "Para gestores públicos e terceiro setor. Nossa plataforma visa simplificar a gestão pública, permitindo acompanhar todas as atividades prestadas aos beneficiados por meio de métricas e relatórios analíticos.",
      "learn_more": "Saiba mais",
      "proj_experience": "Experiência em desenvolvimento de projetos",
      "years_8": "anos",
      "karyotyping": "Uma aplicação web experimental projetada para a montagem automática e supervisionada de cariótipos. Esta plataforma utiliza tecnologias avançadas para simplificar o processo de cariotipagem, tornando-o acessível e eficiente para os pesquisadores.",
      "fine_docs": "Automação na criação de documentos para recursos de multas de trânsito.",
      "cancela_multas": "O Cancela Multas torna acessível a todos a criação de documentos de recursos necessários para protocolar nos órgãos competentes.",
      "epublicity_access": "Acessar o ePublicity",
      "hoplias_access": "Acessar o Hoplias",
      "cancela_access": "Acessar o Cancela Multas",
      "client_area": "Área do cliente",
      "help_center": "Help center",
      "footer": "© daftar.digital todos os direitos reservados. MG - Belo Horizonte CNPJ: 47.732.010/0001-85",
      "portuguese": "Português",
      "english": "Inglês",
      "spanish": "Espanhol"
    }
  },
  es: {
    translation: {
      "innovation": "Innovación, Transformación, Digital",
      "idea_off_paper": "¡Sacamos tu idea del papel!",
      "need_pro_devs": "¿Tu proyecto necesita desarrolladores profesionales?",
      "web_app_specialists": "Somos especialistas en desarrollo web y de aplicaciones",
      "quality_assurance": "Aseguramiento de Calidad",
      "custom_dev": "Desarrollo Personalizado",
      "fully_remote": "Totalmente Remoto",
      "challenging_solutions": "Ofrecemos soluciones para las demandas más desafiantes",
      "purpose_driven": "Estamos impulsados por un propósito mayor: usar la tecnología como una poderosa herramienta para impulsar el futuro. Nos apasiona crear soluciones digitales. Somos un equipo totalmente remoto, conectado en diferentes lugares.",
      "innovation_beyond": "Innovación Más Allá de los Límites",
      "people_without_borders": "Personas & Sin Fronteras",
      "no_borders": "Creemos que la innovación no tiene fronteras. Nuestro equipo está impulsado por la curiosidad y la búsqueda incesante de desafíos.",
      "present_future": "Presente & Futuro",
      "future_trends": "Experimentamos con nuevas tecnologías, exploramos tendencias futuras y creamos soluciones revolucionarias que llevan tus proyectos a niveles inimaginables.",
      "without_borders": "Sin & Fronteras",
      "remote_model": "¿Y lo mejor de todo? incluso a miles de kilómetros de distancia, gracias a nuestro modelo de trabajo 100% remoto.",
      "our_services": "Nuestros Servicios",
      "rd": "Investigación y Desarrollo",
      "ai_solutions": "Utilizamos la IA para crear soluciones inteligentes y automatizadas que optimizan procesos, mejoran la toma de decisiones y impulsan el crecimiento de tu negocio.",
      "custom_software": "Software Personalizado",
      "custom_apps": "Transformamos tus ideas en realidad con aplicaciones y software hechos a medida, creados para satisfacer tus necesidades únicas.",
      "tech_consulting": "Consultoría en Innovación Tecnológica",
      "guide_future": "Guiamos tu empresa hacia el futuro, identificando oportunidades de crecimiento y aplicando tecnologías disruptivas para impulsar tus resultados.",
      "sector_solutions": "Soluciones para Varios Sectores",
      "various_sectors": "Empresarial, agrícola, tercer sector o gestión pública, desarrollamos soluciones inteligentes y efectivas para hacer los procesos más eficientes y escalables.",
      "initiatives": "Nuestras Iniciativas",
      "public_management": "Para gestores públicos y el tercer sector. Nuestra plataforma tiene como objetivo simplificar la gestión pública, permitiéndote monitorear todas las actividades proporcionadas a los beneficiarios a través de métricas e informes analíticos.",
      "learn_more": "Más información",
      "proj_experience": "Experiencia en desarrollo de proyectos",
      "years_8": "años",
      "karyotyping": "Una aplicación web experimental diseñada para el montaje automático y supervisado de cariotipos. Esta plataforma utiliza tecnologías avanzadas para simplificar el proceso de cariotipado, haciéndolo accesible y eficiente para los investigadores.",
      "fine_docs": "Automatización en la creación de documentos para multas de tránsito.",
      "cancela_multas": "Cancela Multas hace accesible para todos la creación de los documentos de apelación necesarios para ser presentados ante las autoridades competentes.",
      "epublicity_access": "Acceder a ePublicity",
      "hoplias_access": "Acceder a Hoplias",
      "cancela_access": "Acceder a Cancela Multas",
      "client_area": "Área de Clientes",
      "help_center": "Centro de Ayuda",
      "footer": "© daftar.digital todos los derechos reservados. MG - Belo Horizonte CNPJ: 47.732.010/0001-85",
      "portuguese": "Portugués",
      "english": "Inglés",
      "spanish": "Español"
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",
  interpolation: {
    escapeValue: false 
  }
});

export default i18n;
