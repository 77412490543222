import React from "react";
import LogoEpb from '../../assets/images/products/logo-epublicity.png'
import LogoHop from '../../assets/images/products/logo-hoplias.png'
import LogoCan from '../../assets/images/logos/output-cancela.png'
import { useTranslation } from 'react-i18next';

const ClientLogo = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="pt-20 pb-36 bg-white relative">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {t('initiatives')}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 bg-custom rounded mt-12 py-10 gap-[30px] items-center">
            <div key="1" className="p-5">
              <img src={LogoEpb} alt="logo-img" className="mx-auto block" style={{ width: '65%' }} />
            </div>
            <div key="2" className="p-5">
              <img src={LogoHop} alt="logo-img" className="mx-auto block" style={{ width: '50%' }} />
            </div>
            <div key="3" className="p-5">
              <img src={LogoCan} alt="logo-img" className="mx-auto block" style={{ width: '20%' }} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientLogo;
