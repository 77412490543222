import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import '../../../node_modules/react-modal-video/scss/modal-video.scss'
import HopliasBanner from "../../assets/images/Hoplias-banner-2.png";
import LogoHop from '../../assets/images/products/logo-hoplias.png'
import { useTranslation } from 'react-i18next';

const FunfactVideoHoplias = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <>
      <section className="md:mt-24 mt-10 relative pb-20" style={{ background: '#161616' }}>
        <div className="bg-[url('../images/home_sec_video.png')] absolute inset-0 w-full"></div>
        <div className="container relative">
          <div className="grid grid-cols-1">
            <div className="max-w-[800px] mx-auto relative -top-[70px]">
              <button onClick={() => setIsOpen(true)} className="lightbox mdi w-[65px] h-[65px] leading-[65px] text-center text-[35px] absolute top-1/2 bg-custom left-1/2 z-10 rounded-full text-white -translate-y-1/2 -translate-x-1/2">
              <i className="mdi mdi-play bg-custom h-[66px] w-[66px] leading-[66px] text-[34px] shadow-[0px_0px_0px_6px_rgb(10,161,149,0.15)] rounded-full inline-block text-center"></i>
              </button>
              <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="tmtxeYZwwxY"
                onClose={() => setIsOpen(false)}
              />
              <img src={HopliasBanner} alt="screen1" className="" style={{ borderRadius: '0.5rem', borderCollapse: 'collapse', width: '100%' }} />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div className="text-white max-w-[500px]">
              <h2 className="text-[35px] font-bold leading-[1.4]">
                <img
                    src={LogoHop}
                    alt="client7"
                    className="h-[50px] my-2 mx-3 opacity-70 inline-block"
                  />
              </h2>
              <p className="text-lg font-bold mb-3" style={{ color: 'white' }}>
                {t('karyotyping')}
              </p>
            </div>
            <div className="md:text-left">
              <Link to="https://hoplias.com/login" className="btn-custom" target="_blank">
                {t('learn_more')}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FunfactVideoHoplias;
